
import LessonPuppetSteps from "./LessonPuppetSteps";
import LessonHouseSteps from "./LessonHouseSteps";
import LessonBackgroundSteps from "./LessonBackgroundSteps";
import LessonTheatreSteps from "./LessonTheatreSteps";

export const CourseTheatreRecipe =
    {
      "version": 1,
      "pathname": "theatre",
      "topLine": "course.theatre.topLine",
      "name": "course.theatre.courseName",
      "description" : "course.theatre.courseDescription",
      "imgPath" : "assets/course/theatre/course-thumbnail_theatre_03.jpg",
      "activitypage" : "https://media.catbears.com/course_theatre/activity_pages/thumb_theatre_activitypage.jpg",
      "lessonsCount": 5,
      "id": "course-theatre",
      "lessons": [
        {
          "pathname" : "intro",
          "type": "SingleStepLesson",
          "size": "small",
          "name": "general.lets-begin!!", 
          "badge": "",
          "coinName": "lets-begin",
          "id": "course-theatre_intro",
          "steps" : [{
            // coin-lets-begin
                        size : "ROW",
                        buttonShape: "coin",
                        coinName: "lets-begin",
                        name: "general.lets-begin!!", 
                        type: "videoTeamwork",
                        AnimalName : "koala",
                        // requirements: [{name: "activityPage"} ],
                        videoThumbnail: "/assets/course/theatre/lesson-thumbnail_puppet.jpeg",
                        id:"lets-begin",
                        stepItems : [
                          { 
                            id:"lets-begin_video",
                            pageState: "video",
                            url: "https://media.catbears.com/course_theatre/the-catbears-intro.mp4",
                            videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
                            isStepGoal: true
                          },
                          // {
                          //   pageState: "discuss",
                          //   img: "/assets/course/theatre/videos-and-thumbnails/alexander-and-mistake.jpg",
                          //   discussTitle: "teamworkSkills.idea-behind-mistake.discussTitle",
                          // }
                        ]
                      }],
          "backgroundColor": "var(--color-skyblue)",
        },
        {
          "type": "LessonListItem",
          "size": "small",
          "name": "course.theatre.lesson-puppet.name",
          "description" : "course.theatre.lesson-puppet.description",
          "activityPageThumb" : "https://media.catbears.com/course_theatre/activity_pages/thumb_puppet_activitypage.jpg",
          "imgPath" : "assets/course/theatre/lesson-thumbnail_puppet.jpeg",
          "badge": "",
          "id": "course-theatre_puppet",
          "steps" : LessonPuppetSteps,
          "pathname" : "puppet",
          "instructorName" : "instructors.meek",
          "AnimalType" : "koala",
          "backgroundColor": "var(--color-skyblue)",
          "teaserThumbnail": "/assets/course/theatre/videos-and-thumbnails/intro-thumbnail.jpg",
          "teaserUrl": "https://media.catbears.com/course_theatre/puppet_step-00_intro_he.mp4",
          "activityPagePDF" : "https://media.catbears.com/course_theatre/activity_pages/catbears_make-a-puppet.pdf",
          "teamworkSkillImgName" : "wheel-of-creation",                                                    
          "teamworkSkillName" : "teamworkSkills.wheel-of-creation.name",                                                    
          "teamworkSkillTodo" : "teamworkSkills.wheel-of-creation.todo"
        },
        { 
          "type": "LessonListItem",
          "size": "small",
          "name": "course.theatre.lesson-house.name",
          "description" : "course.theatre.lesson-house.description",
          "activityPageThumb" : "https://media.catbears.com/course_theatre/activity_pages/thumb_house_activitypage.jpg",
          "imgPath" : "assets/course/theatre/lesson-thumbnail_house.jpeg",
          "badge": "",
          "id": "course-theatre_house",
          "steps" : LessonHouseSteps,
          "pathname" : "house",
          "instructorName" : "instructors.eden",
          "AnimalType" : "crow",
          "backgroundColor": "#EF86FF",
          "teaserThumbnail": "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
          "teaserUrl": "https://media.catbears.com/course_theatre/house_step-00_intro.mp4",
          "activityPagePDF" : "https://media.catbears.com/course_theatre/activity_pages/catbears_build-a-house.pdf",
          "teamworkSkillImgName" : "stop-to-relax",                                                    
          "teamworkSkillName" : "teamworkSkills.stop-to-relax.name",                                                    
          "teamworkSkillTodo" : "teamworkSkills.stop-to-relax.todo"
        },
        {
          "type": "LessonListItem",
          "size": "small",
          "name": "course.theatre.lesson-background.name",
          "description" : "course.theatre.lesson-background.description",
          "activityPageThumb" : "https://media.catbears.com/course_theatre/activity_pages/thumb_background_activitypage.jpg",
          "imgPath" : "assets/course/theatre/lesson-thumbnail_background.jpg",
          "badge": "",
          "id": "course-theatre_background",
          "steps" : LessonBackgroundSteps,
          "pathname" : "background",
          "instructorName" : "instructors.jordy",
          "AnimalType" : "croc",
          "backgroundColor": "#41B04B",
          "teaserUrl": "https://media.catbears.com/course_theatre/background_intro.mp4",
          "activityPagePDF" : "https://media.catbears.com/course_theatre/activity_pages/catbears_background.pdf",
          "teamworkSkillImgName" : "idea-behind-mistake",                                                    
          "teamworkSkillName" : "teamworkSkills.idea-behind-mistake.name",                                                    
          "teamworkSkillTodo" : "teamworkSkills.idea-behind-mistake.todo"
        },
        {
          "type": "LessonListItem",
          "size": "XL",
          "name": "course.theatre.lesson-theatre.name",
          "description" : "course.theatre.lesson-theatre.description",
          "activityPageThumb" : "https://media.catbears.com/course_theatre/activity_pages/thumb_theatre_activitypage.jpg",
          "imgPath" : "assets/course/theatre/lesson-thumbnail_theatre.jpg",
          "badge": "",
          "id": "course-theatre_theatre",
          "steps" : LessonTheatreSteps,
          "pathname" : "theatre",
          "instructorName" : "instructors.angel",
          "AnimalType" : "bunny",
          "backgroundColor": "var(--color-pink)",
          "teaserUrl": "https://media.catbears.com/course_theatre/theatre_intro.mp4",
          "activityPagePDF" : "https://media.catbears.com/course_theatre/activity_pages/catbears_build-a-theatre.pdf",
          "teamworkSkillImgName" : "create-the-turn",                                                    
          "teamworkSkillName" : "teamworkSkills.create-the-turn.name",                                                    
          "teamworkSkillTodo" : "teamworkSkills.create-the-turn.todo"
        }
      ]
  }
  
  export default CourseTheatreRecipe;

