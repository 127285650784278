
const LessonTheatreSteps = [
    { // step 0 - intro
      size : "ROW",
      name: "general.intro", 
      description: "course.theatre.lesson-background.description",
      AnimalName : "bunny",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_theatre-intro.jpg",
      id: "theatre_intro",
      stepItems : [
        { 
          id: "theatre_intro_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_intro.mp4",
          videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
          isStepGoal: true
        }
      ]
    },
    { // Skill
      buttonShape: "circle", // 
      size : "coinImg",
      name: "teamworkSkills.create-the-turn.name", 
      description: "teamworkSkills.create-the-turn.description",
      todo: "teamworkSkills.create-the-turn.todo",
      coinName: "create-the-turn",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/thumb_create-the-turn.jpg",
      id: "tempSkillForTheatre",
      stepItems : [
        { 
          id: "create-the-turn_video-A",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_create-the-turn-A.mp4",
          isStepGoal: true
        },
        {
          id:"discuss-create-the-turn_video-A",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_create-the-team.png",
          discussTitle: "teamworkSkills.create-the-turn.choose-team-title",
          discussSubtitle: "teamworkSkills.create-the-turn.choose-team-subtitle",
        },
        {
          id:"discuss-create-the-turn_glue",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_create-the-turn_glue.jpg",
          discussTitle: "teamworkSkills.create-the-turn.glue-title",
          discussSubtitle: "teamworkSkills.create-the-turn.glue-subtitle",
        },
        { 
          id: "create-the-turn_video-B",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_create-the-turn-B.mp4",
          isStepGoal: true
        },
        {
          id:"discuss-create-the-turn_video-B",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_create-the-turn_1st-turn.jpg",
          discussTitle: "teamworkSkills.create-the-turn.choose-1st-turn-title",
          discussSubtitle: "teamworkSkills.create-the-turn.choose-1st-turn-subtitle",
        },
        {
          id:"discuss-create-the-turn_glue-2nd-time",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_create-the-turn_glue.jpg",
          discussTitle: "teamworkSkills.create-the-turn.glue-title",
          discussSubtitle: "teamworkSkills.create-the-turn.glue-subtitle",
        },
      ]
    },
    { // dotted-lines
      StepNumberToShow: 1,
      name: "course.theatre.lesson-theatre.steps.dotted-lines.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-theatre.steps.dotted-lines.description",
      requirements: [{name: "usedPaper"}, {name: "cardboard-piece"}, {name: "tush"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/theatre_dotted-lines.jpg",
      id: "theatre_dotted-lines",
      stepItems : [
        { 
          id: "theatre_dotted-lines_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_dotted-lines.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_dotted-lines_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/theatre_dotted-lines.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-theatre.steps.dotted-lines.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_dotted-lines_01.jpg"
            },
            {
              text: "course.theatre.lesson-theatre.steps.dotted-lines.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_dotted-lines_02.jpg"
            }
          ],
          // tips: ["course.theatre.lesson-theatre.steps.dotted-lines.tips.tip1"]
        }
      ]
    },
    { // draw-window
      StepNumberToShow: 2,
      name: "course.theatre.lesson-theatre.steps.draw-window.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-theatre.steps.draw-window.description",
      requirements: [{name: "usedPaper"}, {name: "tush"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/theatre_draw-window.jpg",
      id: "theatre_draw-window",
      stepItems : [
        { 
          id: "theatre_draw-window_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_draw-window.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_draw-window_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/theatre_draw-window.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-theatre.steps.draw-window.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_draw-window_01.jpg"
            },
            {
              text: "course.theatre.lesson-theatre.steps.draw-window.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_draw-window_02.jpg"
            },
            {
              text: "course.theatre.lesson-theatre.steps.draw-window.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_draw-window_03.jpg"
            }
          ],
        }
      ]
    },
    { // make-hole
      StepNumberToShow: 3,
      name: "course.theatre.lesson-theatre.steps.make-hole.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-theatre.steps.make-hole.description",
      requirements: [{name: "scissors"}, {name: "masking-tape"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/theatre_make-hole.jpg",
      id: "theatre_make-hole",
      stepItems : [
        { 
          id: "theatre_make-hole_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_make-hole.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_make-hole_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/theatre_make-hole.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-theatre.steps.make-hole.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_make-hole_01.jpg"
            },
            {
              text: "course.theatre.lesson-theatre.steps.make-hole.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_make-hole_02.jpg"
            },
            {
              text: "course.theatre.lesson-theatre.steps.make-hole.bullets.bullet3",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_make-hole_03.jpg"
            }
          ]
        }
      ]
    },
    { // cut-window
      StepNumberToShow: 4,
      name: "course.theatre.lesson-theatre.steps.cut-window.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-theatre.steps.cut-window.description",
      requirements: [{name: "scissors"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/theatre_cut-window.jpg",
      id: "theatre_cut-window",
      stepItems : [
        { 
          id: "theatre_cut-window_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_cut-window.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_cut-window_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/theatre_cut-window.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-theatre.steps.cut-window.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_cut-window_01.jpg"
            },
            {
              text: "course.theatre.lesson-theatre.steps.cut-window.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_cut-window_02.jpg"
            }
          ]
        }
      ]
    },
    { // fold-cardboard 
      StepNumberToShow: 5,
      name: "course.theatre.lesson-theatre.steps.fold-cardboard.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-theatre.steps.fold-cardboard.description",
      requirements: [{name: "scissors"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/theatre_fold-cardboard.jpg",
      id: "theatre_fold-cardboard",
      stepItems : [
        { 
          id: "theatre_fold-cardboard_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_fold-cardboard.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_fold-cardboard_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/theatre_fold-cardboard.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-theatre.steps.fold-cardboard.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_fold-cardboard_01.jpg"
            },
            {
              text: "course.theatre.lesson-theatre.steps.fold-cardboard.bullets.bullet2",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_fold-cardboard_02.jpg"
            }
          ]
        }
      ]
    },
    { // decorate
      StepNumberToShow: 6,
      name: "course.theatre.lesson-theatre.steps.decorate.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-theatre.steps.decorate.description",
      requirements: [{name: "scissors"}, {name: "stick-glue"} ],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/theatre_decorate.jpg",
      id: "theatre_decorate",
      stepItems : [
        { 
          id: "theatre_decorate_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_decorate.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_decorate_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/theatre_decorate.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-theatre.steps.decorate.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_decorate_01.jpg"
            }
          ]
        }
      ]
    },
    { // color
      StepNumberToShow: 7,
      name: "course.theatre.lesson-theatre.steps.color.name",
      size : "S", 
      state: "disabled", 
      description: "course.theatre.lesson-theatre.steps.color.description",
      requirements: [{name: "allColors"}],
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/theatre_color.jpg",
      id: "theatre_color",
      stepItems : [
        { 
          id: "theatre_color_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/theatre_color.mp4",
          isStepGoal: true
        },
        {
          id: "theatre_color_teamwork",
          pageState: "teamWork",
          url: "https://media.catbears.com/course_theatre/theatre_color.mp4",
          string: "teamwork text here",
          bullets: [
            {
              text: "course.theatre.lesson-theatre.steps.color.bullets.bullet1",
              img: "https://media.catbears.com/course_theatre/teamwork-steps/theatre_color_01.jpg"
            }
          ]
        }
      ]
    },
    { // trophy
      name: "finishStep.name",
      size : "CUSTOM-IMG", 
      state: "disabled", 
      ImageToShow: "/assets/icons/icon_trophy.svg",
      videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/trophy-thumbnail.png",
      id:"theatre_trophy",
      stepItems : [
        {
          id:"theatre_recap_trophy_discuss",
          pageState: "discuss",
          img: "/assets/course/theatre/videos-and-thumbnails/thumb_create-the-turn.jpg",
          discussTitle: "general.whoKnows",
          discussSubtitle: "teamworkSkills.create-the-turn.discussTitle",
        },
        {
          id:"background_trophy_cleanup",
          pageState: "discuss",
          img: "/assets/course/theatre/theatre_cleanup.png",
          discussTitle: "cleanup.title",
          discussSubtitle: "cleanup.description"
        },
        { 
          id:"dance_background_video",
          pageState: "video",
          url: "https://media.catbears.com/course_theatre/dance_puppet.mp4",
          isSkippable: true
        },
      ]
    }     
    ]
    
    export default LessonTheatreSteps;