import React, { useState } from 'react';
import "./StepRequirement.scss";
import { useTranslation } from 'react-i18next';

function StepRequirement({directionRow, onClick, type, showDescription, imgPath, hideName, hideBackground, RadioButton, whiteText, sizeXL, sizeL, sizeM}) {

    const {t} = useTranslation();

    let reqName = "";
    let reqImg = "";
    let description = "";
    

    const clickFunc = () => {
        if(onClick){
            onClick();
        }
        if(RadioButton){
            handleToggle()
        }
    }

    switch (type) {
        case 'activityPage':
            reqName = t("requirements.activityPage");
            reqImg = imgPath ? (imgPath) : "/assets/icons/reqImg_activity-page.png";
            break;
        case 'scissors':
            reqName = t("requirements.scissors");
            reqImg = "/assets/icons/reqImg_scissors.png";
            break;
        case 'brush':
            reqName = t("requirements.brush");
            reqImg = "/assets/icons/reqImg_brush.png";
            break;
        case 'bottle-glue':
            reqName = t("requirements.bottle-glue");
            reqImg = "/assets/icons/reqImg_bottle-glue.png";
            description = t("requirements.bottle-glue-description")
            break;
        case 'stick-glue':
            reqName = t("requirements.stick-glue");
            reqImg = "/assets/icons/reqImg_stick-glue.png";
            break;
        case 'stick':
            reqName = t("requirements.stick");
            reqImg = "/assets/icons/reqImg_stick.png";
            description = t("requirements.stick-description")
            break;
        case 'tush':
            reqName = t("requirements.tush");
            reqImg = "/assets/icons/reqImg_tush.png";
            break;
        case 'crayon':
            reqName = t("requirements.crayon");
            reqImg = "/assets/icons/reqImg_crayon.png";
            break;
        case 'pencil-color':
            reqName = t("requirements.pencil-color");
            reqImg = "/assets/icons/reqImg_pencil-color.png";
            break;
        case 'pencil':
            reqName = t("requirements.pencil");
            reqImg = "/assets/icons/reqImg_pencil.png";
            break;
        case 'tape':
            reqName = t("requirements.tape");
            reqImg = "/assets/icons/reqImg_tape.png";
        break;
        case 'bristol':
            reqName = t("requirements.bristol");
            reqImg = "/assets/icons/reqImg_bristol.png";
            description = t("requirements.bristol-description");
        break;
        case 'paper-bag':
            reqName = t("requirements.paper-bag");
            reqImg = "/assets/icons/reqImg_paper-bag.png";
            description = t("requirements.paper-bag-description");
        break;
        case 'cereal':
            reqName = t("requirements.cereal");
            reqImg = "/assets/icons/reqImg_cereal.png";
            description = t("requirements.cereal-description")
        break;
        case 'masking-tape':
            reqName = t("requirements.masking-tape");
            reqImg = "/assets/icons/reqImg_masking-tape.png";
            break;
        case 'cardboard':
            reqName = t("requirements.cardboard");
            reqImg = "/assets/icons/reqImg_cardboard.png";
            break;
        case 'cardboard-piece':
            reqName = t("requirements.cardboard-piece");
            reqImg = "/assets/icons/reqImg_cardboard-piece.png";
            break;
        case 'allColors':
            reqName = t("requirements.allColors");
            reqImg = "/assets/icons/reqImg_all-colors.png";
            description = t("requirements.allColors-description")
        break;
        case 'craftTable':
            reqName = t("onboarding.Onboarding_Environment.craftTable");
            reqImg = "/assets/onboarding/table-and-ipad.png";
            description =  t("onboarding.Onboarding_Environment.craftTable-description")
        break;
        case 'iPad':
            reqName = t("onboarding.Onboarding_Environment.iPad");
            reqImg = "/assets/onboarding/the-catbears-on-iPad.png";
            description =  t("onboarding.Onboarding_Environment.iPad-description")
        break;
        case 'activityPages':
            reqName = t("requirements.activityPage");
            reqImg = imgPath ? ("/" + imgPath) : "/assets/onboarding/the-catbears-activity-pages.png";
            break;
        case 'usedPaper':
            reqName = t("requirements.usedPaper");
            reqImg = "/assets/icons/reqImg_usedPaper.png";
            break;
        default:
            // Handle the case when 'type' does not match any of the specified cases
            break;
    }


    const [RadioButtonON, setRadioButtonON] = useState(false);
    
    const handleToggle = () => {
        setRadioButtonON(!RadioButtonON);
    };

    return (
        <div onClick={clickFunc} className={["stepRequirements--item", (directionRow && "stepRequirements--directionRow"), (hideBackground && "stepRequirements--hideBackground"), (sizeXL && "stepRequirements--item-XL"), (sizeL && "stepRequirements--item-L"), (sizeM && "stepRequirements--item-M")].join(" ")}>
            <div className="stepRequirements--a">
                {RadioButton && <div className={"RadioButton " + (RadioButtonON ? "RadioButtonON" : "")}></div>}
                <div className={["stepRequirements--img", (sizeL && "img_sizeL"), (sizeXL && "img_sizeXL")].join(" ")}>
                    <img src={reqImg} alt={reqName} />
                </div>
            </div>
            <div className="stepRequirements--b">
                {!hideName && <div className={["stepRequirements--name", (whiteText && "whiteText"), ((sizeL || sizeXL) && "name_L")].join(" ")}>{reqName}</div>}
                {showDescription && description && <div className={["stepRequirements--description", (whiteText && "whiteText"), ((sizeL || sizeXL) && "description_L")].join(" ")}>{description}</div>}
            </div>
        </div>
    );
}

export default StepRequirement;
